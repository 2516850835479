import React, { useState, useEffect } from "react";
import Header from "../components/UserHeader";
import { API_URL } from '../config/urlConfig';
import { Link, useParams, useNavigate } from "react-router-dom";
import { FaTrash, FaDownload, FaChartLine } from "react-icons/fa";
import "../styles/ProjectRuns.css";
import { exportToCSV } from '../utils/exportUtils';

const getStatusText = (status) => {
  const statusMap = {
    'in_progress': 'In Progress',
    'completed': 'Completed',
    'error': 'Error'
  };
  return statusMap[status] || status;
};

const ProjectRuns = ({ user }) => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");
  const runsPerPage = 6;
  const navigate = useNavigate();

  // Função atualizada para buscar status das runs
  const fetchRunsStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await fetch(`${API_URL}/runs?projectId=${projectId}`, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();

      if (response.ok && data.runs) {
        setRuns(prevRuns => {
          return prevRuns.map(prevRun => {
            const updatedRun = data.runs.find(r => r.id === prevRun.id);
            return updatedRun || prevRun;
          });
        });
      }
    } catch (error) {
      console.error("Error fetching runs status:", error);
    }
  };

  // Effect to fetch project data
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          navigate('/login');
          return;
        }

        const projectResponse = await fetch(`${API_URL}/project/${projectId}`, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });

        const projectData = await projectResponse.json();

        if (projectResponse.ok) {
          setProject(projectData);
        } else {
          if (projectResponse.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            navigate('/login');
            return;
          } else if (projectResponse.status === 403) {
            setError("Access denied to this project");
            setTimeout(() => navigate('/projects'), 2000);
            return;
          }
          setError("Error loading project");
        }
      } catch (error) {
        console.error("Error fetching project:", error);
        setError("Error loading project");
      }
    };

    fetchProject();
  }, [projectId, navigate]);

  // Efeito para carregar runs inicialmente (manter este useEffect como está)
  useEffect(() => {
    const fetchRuns = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          navigate('/login');
          return;
        }

        const runsResponse = await fetch(`${API_URL}/runs?projectId=${projectId}`, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });

        const runsData = await runsResponse.json();

        if (runsResponse.ok) {
          setRuns(runsData.runs);
        } else {
          if (runsResponse.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            navigate('/login');
          } else {
            setError("Error loading runs");
          }
        }
      } catch (error) {
        console.error("Error fetching runs:", error);
        setError("Error loading runs");
      } finally {
        setLoading(false);
      }
    };

    fetchRuns();
  }, [projectId, navigate]);

  // useEffect para atualização automática (ajustado)
  useEffect(() => {
    const hasInProgressRuns = runs.some(run => run.status === 'in_progress');
    
    let interval;
    if (hasInProgressRuns) {
      // Fazer primeira verificação imediatamente
      fetchRunsStatus();
      // Configurar intervalo para verificações subsequentes
      interval = setInterval(fetchRunsStatus, 5000);
    }

    // Cleanup function
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [runs, projectId, navigate]); // Adicionar navigate como dependência

  const handleDeleteRun = async (runId) => {
    if (!window.confirm("Are you sure you want to delete this run?")) return;
    
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await fetch(`${API_URL}/delete-run/${runId}`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        setRuns(runs.filter((run) => run.id !== runId));
      } else {
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          navigate('/login');
        } else {
          setError("Error deleting run");
        }
      }
    } catch (error) {
      console.error("Error deleting run:", error);
      setError("Error deleting run");
    }
  };

  const handleDownloadRun = async (runId) => {
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await fetch(`${API_URL}/runs/${runId}/download`, {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      });

      if (response.ok) {
        // Converter a resposta para blob e fazer download
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `run-${runId}-results.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          navigate('/login');
        } else {
          setError("Error downloading results");
        }
      }
    } catch (error) {
      console.error("Error downloading results:", error);
      setError("Error downloading results");
    }
  };

  // Função para formatar o número de itens coletados
  const getCollectedItems = (run) => {
    if (run.status === 'in_progress') return '...';
    return run.pages_collected || 0;
  };

  const renderRunCard = (run) => (
    <div key={run.id} className={`run-card ${run.status}`}>
      <div className="run-header">
        <h3>{run.name}</h3>
        <span className={`run-status ${run.status}`}>
          {getStatusText(run.status)}
        </span>
        <button 
          className="delete-run" 
          onClick={() => handleDeleteRun(run.id)}
          title="Delete run"
        >
          <FaTrash />
        </button>
      </div>
      
      <div className="run-info">
        <div className="run-date">
          {new Date(run.created_at).toLocaleDateString()}
        </div>
        
        <div className="detail-item">
          <span className="detail-label">Configured Items:</span>
          <span className="detail-value">{run.max_items || 'N/A'}</span>
        </div>

        <div className="detail-item">
          <span className="detail-label">
            {project?.type === 'youtube' ? 'Videos Collected:' : 'Pages Collected:'}
          </span>
          <span className="detail-value">
            {getCollectedItems(run)}
          </span>
        </div>
      </div>

      <div className="run-actions">
        {user.plan === 'Basic' ? (
          <button 
            onClick={() => handleDownloadRun(run.id)} 
            className="action-btn download-btn"
            disabled={run.status !== 'completed'}
          >
            Download CSV
          </button>
        ) : (
          <Link 
            to={project?.type === 'youtube' 
              ? `/youtube-dashboard/${projectId}?run=${run.id}` 
              : `/site-dashboard/${projectId}?run=${run.id}`} 
            className="action-btn dashboard-btn"
          >
            View Details
          </Link>
        )}
      </div>
    </div>
  );

  if (!user) {
    navigate('/login');
    return null;
  }

  if (loading) {
    return (
      <div className="project-runs">
        <Header user={user} />
        <div className="main-content">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  // Filtering
  const filteredRuns = runs.filter((run) =>
    (filter === "all" || run.status === filter) &&
    run.name.toLowerCase().includes(search.toLowerCase())
  );

  // Pagination
  const indexOfLastRun = currentPage * runsPerPage;
  const indexOfFirstRun = indexOfLastRun - runsPerPage;
  const currentRuns = filteredRuns.slice(indexOfFirstRun, indexOfLastRun);

  return (
    <div className="project-runs">
      <Header user={user} />
      <div className="main-content">
        <div className="runs-header">
          <div className="title-section">
            <h2>{project?.name} - Processing History</h2>
            <span className="runs-count">{runs.length} runs</span>
          </div>
          <Link to={`/crawl-config/${projectId}`} className="create-run-btn">
            <span>+</span>
            New Run
          </Link>
        </div>

        {error && <p className="error-message">{error}</p>}
        
        <div className="filters-section">
          <div className="search-box">
            <input
              type="text"
              placeholder="Search runs..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="filter-box">
            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option value="all">All Status</option>
              <option value="in_progress">In Progress</option>
              <option value="completed">Completed</option>
              <option value="failed">Failed</option>
            </select>
          </div>
        </div>

        <div className="runs-grid">
          {currentRuns.length > 0 ? (
            currentRuns.map(run => renderRunCard(run))
          ) : (
            <div className="no-runs">
              <p>No runs found for this project.</p>
              <Link 
                to={`/crawl-config/${projectId}`} 
                className="create-run-btn"
              >
                <span>+</span>
                Create New Run
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectRuns;