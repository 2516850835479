import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import UserHome from "./pages/UserHome";
import UserSettings from "./pages/UserSettings";
import CreateProject from './pages/CreateProject';
import CrawlConfig from './pages/CrawlConfig';
import YouTubeDashboard from './pages/YouTubeDashboard';
import SiteDashboard from './pages/SiteDashboard';
import Projects from './pages/Projects';
import ProjectRuns from './pages/ProjectRuns';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import CompleteSignup from './pages/CompleteSignup';
import Plans from './pages/Plans';
import AuthCallback from './pages/AuthCallback';
import Payments from './pages/Payments';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Carregar usuário salvo no localStorage ao iniciar
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    
    if (storedUser && token) {
      setUser(JSON.parse(storedUser));
      console.log("User loaded from localStorage:", JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  // Função para salvar usuário no estado e no localStorage
  const handleLogin = (userData) => {
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
    // O token já é salvo na página de login
  };

  // Função de logout
  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token"); // Remove também o token
  };

  // Atualizar usuário sem perder estado
  const updateUser = (updatedData) => {
    setUser((prevUser) => {
      if (!prevUser) return null;
      const newUser = { ...prevUser, ...updatedData };
      localStorage.setItem("user", JSON.stringify(newUser));
      return newUser;
    });
  };

  if (loading) return <p>Loading...</p>;

  return (
    <Router>
      <Routes>
        <Route 
          path="/"
          element={<Home />}
        />
        <Route
          path="/login"
          element={<Login setUser={handleLogin} />}
        />
        <Route
          path="/signup"
          element={<Signup setUser={handleLogin} />}
        />
        <Route
          path="/user-home"
          element={user ? <UserHome user={user} onLogout={handleLogout} /> : <Navigate to="/login" />}
        />
        <Route
          path="/projects"
          element={user ? <Projects user={user} /> : <Navigate to="/login" />}
        />
        <Route
          path="/settings"
          element={user ? <UserSettings user={user} onLogout={handleLogout} updateUser={updateUser} /> : <Navigate to="/login" />}
        />
        <Route
          path="/youtube-dashboard/:projectId"
          element={<YouTubeDashboard />}
        />
        <Route
          path="/site-dashboard/:projectId"
          element={<SiteDashboard />}
        />
        <Route
          path="/create-project"
          element={user ? <CreateProject user={user} /> : <Navigate to="/login" />}
        />
        <Route
          path="/crawl-config/:projectId"
          element={user ? <CrawlConfig user={user} /> : <Navigate to="/login" />}
        />
        <Route
          path="/project/:projectId/runs"
          element={user ? <ProjectRuns user={user} /> : <Navigate to="/login" />}
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/complete-signup" element={<CompleteSignup setUser={setUser} />} />
        <Route path="/auth/callback" element={<AuthCallback setUser={setUser} />} />
        <Route
          path="/payments"
          element={user ? <Payments user={user} /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
};

export default App;