import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from '../config/urlConfig';
import Header from "../components/UserHeader";
import "../styles/CrawlConfig.css";

const planLimits = {
  Basic: { projects: 1, pages: 3000 },
  Guru: { projects: 4, pages: 20000 },
  Pro: { projects: 10, pages: 80000 },
  Business: { projects: "Unlimited", pages: "Unlimited" },
};

const CrawlConfig = ({user}) => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [crawlName, setCrawlName] = useState("");
  const [maxItems, setMaxItems] = useState(100);
  const [userUsage, setUserUsage] = useState({ pagesUsed: 0 });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [siteConfig, setSiteConfig] = useState({
    allowed_patterns: [""],
    disallowed_patterns: [],
    query_params: {
      use_all: false,
      ignore: [],
      keep: []
    }
  });
  const [showAdvanced, setShowAdvanced] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          setError("Session expired. Please login again.");
          navigate('/login');
          return;
        }

        // Buscar projeto e uso do usuário em paralelo
        const [projectResponse, usageResponse] = await Promise.all([
          fetch(`${API_URL}/project/${projectId}`, {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json"
            }
          }),
          fetch(`${API_URL}/user/usage`, {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json"
            }
          })
        ]);

        const projectData = await projectResponse.json();
        const usageData = await usageResponse.json();

        if (projectResponse.ok) {
          setProject(projectData);
          
          // Criar padrão regex baseado na URL do projeto
          if (projectData.url) {
            try {
              const urlObj = new URL(projectData.url);
              const domain = urlObj.hostname.replace(/\./g, '\\.');
              const pattern = `^https?://${domain}/.*`;
              
              setSiteConfig(prev => ({
                ...prev,
                allowed_patterns: [pattern]
              }));
            } catch (e) {
              console.error('Error parsing URL:', e);
            }
          }
        }
        
        if (usageResponse.ok) {
          setUserUsage(usageData);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error loading configuration.");
      }
    };

    fetchData();
  }, [projectId, navigate]);

  const calculateRemainingItems = () => {
    const userPlan = user?.plan || 'Basic';
    const planLimit = planLimits[userPlan].pages;
    
    if (planLimit === "Unlimited") return Infinity;
    return planLimit - userUsage.pagesUsed;
  };

  const renderSiteConfigForm = () => {
    if (project.type !== 'site') return null;

    // Função para gerar o padrão regex baseado nas opções selecionadas
    const generatePattern = (baseUrl, options = {}) => {
      const urlObj = new URL(baseUrl);
      const domain = urlObj.hostname.replace(/\./g, '\\.');
      return `^https?://${domain}/.*`;
    };

    return (
      <div className="site-config-section">
        <h3>Crawl Configuration</h3>
        
        {/* Crawl Behavior */}
        <div className="form-group">
          <label>Crawl Behavior</label>
          <div className="crawl-behavior">
            <select
              value={siteConfig.crawl_behavior || "follow_everything"}
              onChange={(e) => {
                const behavior = e.target.value;
                setSiteConfig(prev => ({
                  ...prev,
                  crawl_behavior: behavior,
                  allowed_patterns: [generatePattern(project.url, { behavior })]
                }));
              }}
            >
              <option value="follow_everything">Follow all links</option>
              <option value="stay_in_directory">Stay in the current directory</option>
              <option value="stay_in_subdomain">Include subdomains</option>
            </select>
          </div>
        </div>

        {/* URL Parameters */}
        <div className="form-group">
          <label>URL Parameters</label>
          <div className="url-params-config">
            <select
              value={siteConfig.url_params || "keep_all"}
              onChange={(e) => {
                setSiteConfig(prev => ({
                  ...prev,
                  url_params: e.target.value,
                  query_params: {
                    ...prev.query_params,
                    use_all: e.target.value === "keep_all"
                  }
                }));
              }}
            >
              <option value="keep_all">Keep all parameters</option>
              <option value="ignore_all">Ignore all parameters</option>
              <option value="custom">Custom configuration</option>
            </select>
          </div>
        </div>

        {/* Advanced Settings - Collapsed by default */}
        <div className="advanced-settings">
          <button 
            type="button" 
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="advanced-toggle"
          >
            Advanced Settings
          </button>
          
          {showAdvanced && (
            <div className="advanced-content">
              <div className="form-group">
                <label>Allowed URL Patterns (Regex)</label>
                <div className="pattern-list">
                  {siteConfig.allowed_patterns.map((pattern, index) => (
                    <div key={index} className="pattern-input">
                      <input
                        type="text"
                        value={pattern}
                        onChange={(e) => {
                          const newPatterns = [...siteConfig.allowed_patterns];
                          newPatterns[index] = e.target.value;
                          setSiteConfig({...siteConfig, allowed_patterns: newPatterns});
                        }}
                        placeholder="^https?://[^/]*example.com/"
                      />
                      <button 
                        type="button"
                        onClick={() => {
                          const newPatterns = siteConfig.allowed_patterns.filter((_, i) => i !== index);
                          setSiteConfig({...siteConfig, allowed_patterns: newPatterns});
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button 
                    type="button"
                    onClick={() => setSiteConfig({
                      ...siteConfig, 
                      allowed_patterns: [...siteConfig.allowed_patterns, ""]
                    })}
                  >
                    Add Pattern
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleStartCrawl = async () => {
    if (!crawlName.trim()) {
      setError("Please enter a name for the run.");
      return;
    }

    const remainingItems = calculateRemainingItems();
    if (maxItems > remainingItems) {
      setError(`You only have ${remainingItems} items available in your ${user.plan} plan.`);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        setError("Session expired. Please login again.");
        navigate('/login');
        return;
      }

      // Determinar qual endpoint usar baseado no tipo do projeto
      const endpoint = project.type === 'site' 
        ? `${API_URL}/api/start-site-crawler`
        : `${API_URL}/start-crawler`;

      // Preparar o corpo da requisição baseado no tipo do projeto
      const requestBody = project.type === 'site'
        ? {
            projectId,
            url: project.url,
            crawlName,
            maxItems,
            config: {
              ...siteConfig,
              crawl_behavior: siteConfig.crawl_behavior || "follow_everything",
              url_params: siteConfig.url_params || "keep_all"
            }
          }
        : {
            projectId,
            type: project.type,
            url: project.url,
            crawlName,
            maxItems
          };

      const response = await fetch(endpoint, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        navigate(`/project/${projectId}/runs`);
      } else {
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          navigate('/login');
        } else {
          // Mostrar erro mais detalhado em desenvolvimento
          if (process.env.NODE_ENV === 'development') {
            setError(`Error starting the crawler: ${JSON.stringify(data)}`);
          } else {
            setError(data.error || "Error starting the crawler.");
          }
        }
      }
    } catch (error) {
      // Mostrar erro mais detalhado em desenvolvimento
      if (process.env.NODE_ENV === 'development') {
        setError(`Error starting crawler: ${error.message}`);
      } else {
        setError("Error starting the crawler.");
      }
      console.error("Error starting crawler:", error);
    }
  };

  if (!project) {
    return (
      <div className="crawl-config">
        <Header user={user} />
        <main className="main-content">
          <p className="loading">Loading...</p>
        </main>
      </div>
    );
  }

  const remainingItems = calculateRemainingItems();

  return (
    <div className="crawl-config">
      <Header user={user} />
      <main className="main-content">
        <div className="crawl-config-header">
          <div className="title-section">
            <h2>Run Configuration</h2>
            <p className="subtitle">Configure your tracking run for {project.name}</p>
          </div>
        </div>

        <div className="form-container">
          <div className="project-info">
            <div className="info-item">
              <span className="info-label">Project Type</span>
              <span className={`project-type ${project.type}`}>
                {project.type === "youtube" ? "YouTube" : "Website"}
              </span>
            </div>
            <div className="info-item">
              <span className="info-label">Target URL</span>
              <span className="project-url">{project.url}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Plan Usage</span>
              <span className="plan-usage">
                {userUsage.pagesUsed} of {planLimits[user?.plan]?.pages === "Unlimited" ? "Unlimited" : planLimits[user?.plan]?.pages} items used
              </span>
            </div>
          </div>

          <form onSubmit={(e) => { e.preventDefault(); handleStartCrawl(); }}>
            <div className="form-group">
              <label htmlFor="crawlName">Run Name</label>
              <input
                id="crawlName"
                type="text"
                value={crawlName}
                onChange={(e) => setCrawlName(e.target.value)}
                placeholder="Ex.: Daily Check or Weekly Analysis"
                required
              />
              <span className="input-hint">
                Give your run a descriptive name to easily identify it later
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="maxItems">
                Crawl Limits
                <span className="limit-info">
                  (Available: {remainingItems === Infinity ? "Unlimited" : remainingItems})
                </span>
              </label>
              <div className="crawl-limits-input">
                <span>Crawl up to</span>
                <input
                  id="maxItems"
                  type="number"
                  value={maxItems}
                  onChange={(e) => setMaxItems(Math.min(Number(e.target.value), remainingItems))}
                  min="1"
                  max={remainingItems}
                  required
                />
                <span>URLs</span>
              </div>
              <span className="input-hint">
                Define the maximum number of pages to crawl in this run
              </span>
            </div>

            {project.type === 'site' && renderSiteConfigForm()}

            {error && <p className="error-message">{error}</p>}

            <div className="form-actions">
              <button 
                type="button" 
                className="secondary-btn"
                onClick={() => navigate(`/project/${projectId}/runs`)}
              >
                Cancel
              </button>
              <button 
                type="submit" 
                className="primary-btn"
                disabled={!crawlName.trim() || maxItems < 1}
              >
                Start Run
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default CrawlConfig;