import React, { useState, useEffect, useMemo, useRef } from "react";
import { API_URL } from '../config/urlConfig';
import { useParams, useNavigate } from "react-router-dom";
import "../styles/SiteDashboard.css";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  PieChart, Pie, Cell, LineChart, Line, Legend, ScatterChart, Scatter
} from 'recharts';
import { FaTable, FaDownload, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

const SiteDashboard = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");

  const [metrics, setMetrics] = useState({
    totalPages: 0,
    averageLoadTime: 0,
    averagePageSize: 0,
    statusCodes: {},
    contentTypes: {},
    seoMetrics: {
      avgTitleLength: 0,
      avgDescriptionLength: 0,
      pagesWithMissingTitles: 0,
      pagesWithMissingDescriptions: 0,
      totalSeoScore: 0
    }
  });

  const [insights, setInsights] = useState({
    internalLinksDistribution: [],
    externalLinksDistribution: [],
    loadTimePerformance: [],
    contentSizeDistribution: [],
    topInternalPages: [],
    topExternalDomains: [],
    h1Distribution: [],
    metaTagsUsage: [],
    linkDistribution: [],
    topExternalDomains: [],
    seoIssuesDistribution: [],
    metaTagsPresence: []
  });

  // Adicionar novos estados
  const [selectedRun, setSelectedRun] = useState('all');
  const [runs, setRuns] = useState([]);
  const [urlFilterType, setUrlFilterType] = useState('contains');

  // Modificar o estado de filtros existente
  const [filters, setFilters] = useState({
    url: "",
    urlFilterType: "contains", // novo campo
    statusCode: "all",
    minLoadTime: 0,
    maxLoadTime: Number.MAX_SAFE_INTEGER,
    minSeoScore: 0,
    maxSeoScore: 100,
    runId: 'all' // novo campo
  });

  // Adicionar novos estados para a tabela
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // Adicionar no início do componente, junto com os outros estados
  const [columns, setColumns] = useState([
    { key: 'url', label: 'URL' },
    { key: 'status_code', label: 'Status' },
    { key: 'load_time_ms', label: 'Load Time (ms)' },
    { key: 'page_size_kb', label: 'Size (KB)' },
    { key: 'seoScore', label: 'SEO Score' },
    { key: 'title', label: 'Title' },
    { key: 'meta_description', label: 'Meta Description' },
    { key: 'h1_tags', label: 'H1 Tags' }
  ]);
  const [selectedColumns, setSelectedColumns] = useState(['url', 'status_code', 'load_time_ms', 'page_size_kb', 'seoScore']);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const columnSelectorRef = useRef(null);

  // Adicionar este useEffect para fechar o seletor quando clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (columnSelectorRef.current && !columnSelectorRef.current.contains(event.target)) {
        setShowColumnSelector(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Função para calcular score SEO de uma página
  const calculatePageSeoScore = (page) => {
    let score = 0;
    const checks = {
      hasTitle: page.title?.length > 0,
      titleLength: page.title?.length >= 30 && page.title?.length <= 60,
      hasMetaDescription: page.meta_description?.length > 0,
      metaDescriptionLength: page.meta_description?.length >= 120 && page.meta_description?.length <= 160,
      hasH1: page.h1_tags?.length > 0,
      statusCode: page.status_code === 200,
      loadTime: page.load_time_ms < 3000,
      hasMetaKeywords: page.meta_keywords?.length > 0,
      hasImages: page.images_count > 0
    };

    // Pontuar cada check
    if (checks.hasTitle) score += 15;
    if (checks.titleLength) score += 10;
    if (checks.hasMetaDescription) score += 15;
    if (checks.metaDescriptionLength) score += 10;
    if (checks.hasH1) score += 15;
    if (checks.statusCode) score += 15;
    if (checks.loadTime) score += 10;
    if (checks.hasMetaKeywords) score += 5;
    if (checks.hasImages) score += 5;

    return score;
  };

  // Função para buscar runs
  const fetchRuns = async () => {
    try {
      const response = await fetch(`/api/projects/${projectId}/runs`);
      if (!response.ok) {
        throw new Error('Failed to fetch runs');
      }
      const runsData = await response.json();
      setRuns(runsData.runs); // Ajustando para acessar o array de runs corretamente
    } catch (error) {
      console.error("Error fetching runs:", error);
      setError("Failed to load runs data");
    }
  };

  // Modificar a lógica de filtro de URL
  const checkUrlMatch = (pageUrl, filterUrl, filterType) => {
    const pageUrlLower = pageUrl.toLowerCase();
    const filterUrlLower = filterUrl.toLowerCase();

    switch (filterType) {
      case 'equals':
        return pageUrlLower === filterUrlLower;
      case 'contains':
        return pageUrlLower.includes(filterUrlLower);
      case 'notContains':
        return !pageUrlLower.includes(filterUrlLower);
      case 'startsWith':
        return pageUrlLower.startsWith(filterUrlLower);
      case 'endsWith':
        return pageUrlLower.endsWith(filterUrlLower);
      default:
        return true;
    }
  };

  // Atualizar o filteredData
  const filteredData = useMemo(() => {
    return data.filter((page) => {
      const isUrlMatch = !filters.url || 
        checkUrlMatch(page.url, filters.url, filters.urlFilterType);
      
      const isStatusMatch = filters.statusCode === "all" || 
        page.status_code.toString() === filters.statusCode;
      
      const loadTime = page.load_time_ms || 0;
      const isLoadTimeMatch = loadTime >= filters.minLoadTime && 
        (filters.maxLoadTime === Number.MAX_SAFE_INTEGER || loadTime <= filters.maxLoadTime);

      const seoScore = calculatePageSeoScore(page);
      const isSeoScoreMatch = seoScore >= filters.minSeoScore && 
        seoScore <= filters.maxSeoScore;

      const isRunMatch = filters.runId === 'all' || page.run_id === filters.runId;

      return isUrlMatch && isStatusMatch && isLoadTimeMatch && isSeoScoreMatch && isRunMatch;
    });
  }, [data, filters]);

  // Função para calcular métricas gerais
  const calculateMetrics = (data) => {
    if (!data || data.length === 0) return {
      totalPages: 0,
      averageLoadTime: 0,
      averagePageSize: 0,
      statusCodes: {},
      contentTypes: {},
      seoMetrics: {
        avgTitleLength: 0,
        avgDescriptionLength: 0,
        pagesWithMissingTitles: 0,
        pagesWithMissingDescriptions: 0,
        totalSeoScore: 0
      }
    };

    return {
      totalPages: data.length,
      averageLoadTime: Math.round(
        data.reduce((acc, page) => acc + (page.load_time_ms || 0), 0) / data.length
      ),
      averagePageSize: Math.round(
        data.reduce((acc, page) => acc + (page.page_size_kb || 0), 0) / data.length
      ),
      statusCodes: data.reduce((acc, page) => {
        acc[page.status_code] = (acc[page.status_code] || 0) + 1;
        return acc;
      }, {}),
      contentTypes: data.reduce((acc, page) => {
        const contentType = page.content_type || 'unknown';
        acc[contentType] = (acc[contentType] || 0) + 1;
        return acc;
      }, {}),
      seoMetrics: {
        avgTitleLength: Math.round(
          data.reduce((acc, page) => acc + (page.title?.length || 0), 0) / data.length
        ),
        avgDescriptionLength: Math.round(
          data.reduce((acc, page) => acc + (page.meta_description?.length || 0), 0) / data.length
        ),
        pagesWithMissingTitles: data.filter(page => !page.title || page.title.length === 0).length,
        pagesWithMissingDescriptions: data.filter(page => !page.meta_description || page.meta_description.length === 0).length,
        totalSeoScore: Math.round(
          data.reduce((acc, page) => acc + calculatePageSeoScore(page), 0) / data.length
        )
      }
    };
  };

  // Função para gerar insights
  const generateInsights = (pages) => {
    const insights = {
      loadTimeDistribution: [
        { range: '0-1s', count: 0 },
        { range: '1-2s', count: 0 },
        { range: '2-3s', count: 0 },
        { range: '3s+', count: 0 }
      ],
      statusCodeDistribution: [],
      contentTypeDistribution: [],
      linkDistribution: pages.map(page => ({
        url: page.url,
        internal: page.internal_links?.length || 0,
        external: page.external_links?.length || 0
      })),
      topInternalPages: calculateTopPages(pages, 'internal_links'),
      topExternalDomains: calculateTopDomains(pages),
      h1Distribution: calculateTopPages(pages, 'h1_tags'),
      metaTagsUsage: calculateTopPages(pages, 'meta_keywords'),
      seoIssuesDistribution: calculateSeoIssues(pages),
      metaTagsPresence: [
        { name: 'With Meta Description', value: pages.length - metrics.seoMetrics.pagesWithMissingDescriptions },
        { name: 'Missing Meta Description', value: metrics.seoMetrics.pagesWithMissingDescriptions },
        { name: 'With Title', value: pages.length - metrics.seoMetrics.pagesWithMissingTitles },
        { name: 'Missing Title', value: metrics.seoMetrics.pagesWithMissingTitles }
      ]
    };

    // Processar distribuição de tempo de carregamento
    pages.forEach(page => {
      const loadTime = page.load_time_ms / 1000; // converter para segundos
      if (loadTime <= 1) insights.loadTimeDistribution[0].count++;
      else if (loadTime <= 2) insights.loadTimeDistribution[1].count++;
      else if (loadTime <= 3) insights.loadTimeDistribution[2].count++;
      else insights.loadTimeDistribution[3].count++;
    });

    setInsights(insights);
  };

  // Função para calcular o SEO Score
  const calculateSEOScore = () => {
    if (!data.length) return 0;

    let totalScore = 0;
    data.forEach(page => {
      let pageScore = 0;
      
      // Título presente e com tamanho adequado (entre 30 e 60 caracteres)
      if (page.title) {
        pageScore += 20;
        if (page.title.length >= 30 && page.title.length <= 60) {
          pageScore += 10;
        }
      }

      // Meta descrição presente e com tamanho adequado (entre 120 e 160 caracteres)
      if (page.meta_description) {
        pageScore += 20;
        if (page.meta_description.length >= 120 && page.meta_description.length <= 160) {
          pageScore += 10;
        }
      }

      // H1 presente
      if (page.h1_tags && page.h1_tags.length > 0) {
        pageScore += 20;
      }

      // Status code 200
      if (page.status_code === 200) {
        pageScore += 10;
      }

      // Tempo de carregamento < 3s
      if (page.load_time_ms < 3000) {
        pageScore += 10;
      }

      totalScore += pageScore;
    });

    return Math.round(totalScore / data.length);
  };

  // Função para calcular as páginas mais linkadas
  const calculateTopPages = (pages, linkType) => {
    // Criar um mapa de contagem de links para cada URL
    const linkCount = new Map();
    
    pages.forEach(page => {
      const links = page[linkType] || [];
      links.forEach(link => {
        linkCount.set(link, (linkCount.get(link) || 0) + 1);
      });
    });

    // Converter para array e ordenar
    return Array.from(linkCount.entries())
      .map(([url, count]) => ({ url, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10); // Top 10 páginas
  };

  // Função para calcular os domínios externos mais linkados
  const calculateTopDomains = (pages) => {
    const domainCount = new Map();
    
    pages.forEach(page => {
      const externalLinks = page.external_links || [];
      externalLinks.forEach(link => {
        try {
          const domain = new URL(link).hostname;
          domainCount.set(domain, (domainCount.get(domain) || 0) + 1);
        } catch (e) {
          console.warn('Invalid URL:', link);
        }
      });
    });

    return Array.from(domainCount.entries())
      .map(([domain, count]) => ({ domain, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10); // Top 10 domínios
  };

  // Função para calcular a distribuição de problemas SEO
  const calculateSeoIssues = (pages) => {
    const issues = {
      missingTitle: 0,
      shortTitle: 0,
      longTitle: 0,
      missingMetaDescription: 0,
      shortMetaDescription: 0,
      longMetaDescription: 0,
      missingH1: 0,
      multipleH1: 0,
      slowLoadTime: 0,
      largePageSize: 0
    };

    pages.forEach(page => {
      // Verificar título
      if (!page.title) {
        issues.missingTitle++;
      } else {
        if (page.title.length < 30) issues.shortTitle++;
        if (page.title.length > 60) issues.longTitle++;
      }

      // Verificar meta description
      if (!page.meta_description) {
        issues.missingMetaDescription++;
      } else {
        if (page.meta_description.length < 120) issues.shortMetaDescription++;
        if (page.meta_description.length > 160) issues.longMetaDescription++;
      }

      // Verificar H1
      if (!page.h1_tags || page.h1_tags.length === 0) {
        issues.missingH1++;
      } else if (page.h1_tags.length > 1) {
        issues.multipleH1++;
      }

      // Verificar performance
      if (page.load_time_ms > 3000) {
        issues.slowLoadTime++;
      }
      if (page.page_size_bytes > 5242880) { // 5MB
        issues.largePageSize++;
      }
    });

    // Converter para formato adequado para gráficos
    return Object.entries(issues)
      .map(([issue, count]) => ({
        issue: issue.replace(/([A-Z])/g, ' $1').toLowerCase(), // Formatar nome do problema
        count,
        severity: getSeverity(issue, count, pages.length)
      }))
      .filter(issue => issue.count > 0)
      .sort((a, b) => b.count - a.count);
  };

  // Função auxiliar para determinar a severidade dos problemas
  const getSeverity = (issue, count, totalPages) => {
    const percentage = (count / totalPages) * 100;
    
    // Definir severidade baseada no tipo do problema e sua frequência
    const severityMap = {
      missingTitle: percentage > 5 ? 'high' : 'medium',
      missingMetaDescription: percentage > 10 ? 'high' : 'medium',
      missingH1: percentage > 5 ? 'high' : 'medium',
      multipleH1: percentage > 10 ? 'medium' : 'low',
      slowLoadTime: percentage > 20 ? 'high' : 'medium',
      largePageSize: percentage > 15 ? 'high' : 'medium',
      shortTitle: percentage > 15 ? 'medium' : 'low',
      longTitle: percentage > 15 ? 'medium' : 'low',
      shortMetaDescription: percentage > 15 ? 'medium' : 'low',
      longMetaDescription: percentage > 15 ? 'medium' : 'low'
    };

    return severityMap[issue] || 'medium';
  };

  // Função para ordenar dados
  const handleSort = (key) => {
    setSortConfig(prevSort => ({
      key,
      direction: prevSort.key === key && prevSort.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  // Função para renderizar ícone de ordenação
  const renderSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <FaSort className="site-sort-icon" />;
    }
    return sortConfig.direction === 'asc' ? 
      <FaSortUp className="site-sort-icon active" /> : 
      <FaSortDown className="site-sort-icon active" />;
  };

  // Função para exportar dados para CSV
  const exportToCSV = () => {
    if (filteredData.length === 0) return;

    const selectedColumnInfo = columns.filter(col => selectedColumns.includes(col.key));
    const headers = selectedColumnInfo.map(col => col.label);
    
    const csvContent = [
      headers.join(','),
      ...filteredData.map(page => 
        selectedColumns.map(colKey => {
          let value = colKey === 'seoScore' 
            ? calculatePageSeoScore(page)
            : colKey === 'h1_tags'
              ? (page[colKey]?.join('; ') || '')
              : (page[colKey] || '');
          
          // Escapar vírgulas e aspas se necessário
          value = String(value);
          if (value.includes(',') || value.includes('"')) {
            value = `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        }).join(',')
      )
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `site_analysis_${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Função para ordenar e paginar dados
  const sortData = (data, sortKey, direction) => {
    if (!sortKey) return data;

    return [...data].sort((a, b) => {
      let aValue = sortKey === 'seoScore' ? calculatePageSeoScore(a) : a[sortKey];
      let bValue = sortKey === 'seoScore' ? calculatePageSeoScore(b) : b[sortKey];

      // Converter para número se possível
      if (!isNaN(aValue) && !isNaN(bValue)) {
        aValue = Number(aValue);
        bValue = Number(bValue);
      }

      if (direction === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  };

  // Calcular dados ordenados e paginados usando useMemo
  const sortedAndPaginatedData = useMemo(() => {
    const sortedData = sortData(filteredData, sortConfig.key, sortConfig.direction);
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredData, sortConfig, currentPage, rowsPerPage]);

  // Calcular total de páginas
  const totalPages = useMemo(() => 
    Math.ceil(filteredData.length / rowsPerPage),
    [filteredData.length, rowsPerPage]
  );

  // Efeito para carregar os dados
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          navigate('/login');
          return;
        }

        // Pegar o runId da URL se existir
        const urlParams = new URLSearchParams(window.location.search);
        const runId = urlParams.get('run');

        const url = new URL(`${API_URL}/crawled-pages`);
        url.searchParams.append('projectId', projectId);
        if (runId) {
          url.searchParams.append('runId', runId);
        }

        const response = await fetch(url, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });

        const result = await response.json();

        if (response.ok) {
          setData(result.pages);
          generateInsights(result.pages);
        } else {
          setError("Error loading data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error loading data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectId, navigate]);

  // Usar no useEffect
  useEffect(() => {
    fetchRuns();
  }, [projectId]);

  // useEffect para atualizar métricas quando os dados mudarem
  useEffect(() => {
    if (data.length > 0) {
      const newMetrics = calculateMetrics(filteredData);
      setMetrics(newMetrics);
    }
  }, [filteredData, data.length]);

  const statusOptions = [
    { value: "all", label: "All Status" },
    { value: "200", label: "200 - Success" },
    { value: "301", label: "301 - Moved Permanently" },
    { value: "302", label: "302 - Found" },
    { value: "400", label: "400 - Bad Request" },
    { value: "404", label: "404 - Not Found" },
    { value: "500", label: "500 - Server Error" }
  ];

  // Componente dos cards
  const MetricCards = () => {
    return (
      <div className="site-metric-cards">
        <div className="site-metric-card">
          <h3>Pages Crawled</h3>
          <p>{metrics.totalPages}</p>
        </div>

        <div className="site-metric-card">
          <h3>Average Load Time</h3>
          <p>{metrics.averageLoadTime}ms</p>
        </div>

        <div className="site-metric-card">
          <h3>Average Page Size</h3>
          <p>{metrics.averagePageSize}KB</p>
        </div>

        <div className="site-metric-card">
          <h3>SEO Score</h3>
          <p>{metrics.seoMetrics.totalSeoScore}%</p>
        </div>

        <div className="site-metric-card">
          <h3>Missing Titles</h3>
          <p>{metrics.seoMetrics.pagesWithMissingTitles}</p>
        </div>

        <div className="site-metric-card">
          <h3>Missing Descriptions</h3>
          <p>{metrics.seoMetrics.pagesWithMissingDescriptions}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="site-dashboard">
      <div className="site-dashboard-header">
        <h1>Site Analysis Dashboard</h1>
      </div>

      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div className="site-filters-section">
            <div className="site-filter-group">
              <label>Run:</label>
              <select 
                value={filters.runId}
                onChange={(e) => setFilters(prev => ({ ...prev, runId: e.target.value }))}
              >
                <option value="all">All Runs</option>
                {runs.map(run => (
                  <option key={run.id} value={run.id}>
                    Run #{run.id} - {new Date(run.created_at).toLocaleDateString()}
                  </option>
                ))}
              </select>
            </div>

            <div className="site-filter-group">
              <label>URL:</label>
              <select 
                value={filters.urlFilterType}
                onChange={(e) => setFilters(prev => ({ ...prev, urlFilterType: e.target.value }))}
              >
                <option value="equals">Equals</option>
                <option value="contains">Contains</option>
                <option value="notContains">Does not contain</option>
                <option value="startsWith">Starts with</option>
                <option value="endsWith">Ends with</option>
              </select>
              <input
                type="text"
                value={filters.url}
                onChange={(e) => setFilters(prev => ({ ...prev, url: e.target.value }))}
                placeholder="Enter URL..."
              />
            </div>

            <div className="site-filter-group">
              <label>Status:</label>
              <select 
                className="site-status-select"
                value={filters.statusCode}
                onChange={(e) => setFilters(prev => ({ ...prev, statusCode: e.target.value }))}
              >
                {statusOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <label>
              Load Time (ms):
              <div className="site-range-inputs">
                <input 
                  type="number" 
                  value={filters.minLoadTime} 
                  onChange={(e) => setFilters({ ...filters, minLoadTime: Number(e.target.value) })}
                  placeholder="Min"
                />
                <input 
                  type="number" 
                  value={filters.maxLoadTime === Number.MAX_SAFE_INTEGER ? '' : filters.maxLoadTime} 
                  onChange={(e) => setFilters({ ...filters, maxLoadTime: e.target.value ? Number(e.target.value) : Number.MAX_SAFE_INTEGER })}
                  placeholder="Max"
                />
              </div>
            </label>
          </div>

          <MetricCards />

          {/* Gráficos de Performance */}
          <div className="site-charts-container">
            <div className="site-chart-box">
              <h3>Load Time Distribution</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={insights.loadTimeDistribution}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="range" />
                  <YAxis />
                  <Tooltip 
                    formatter={(value) => [`${value} pages`, 'Count']}
                    labelStyle={{ color: '#e0e0e0' }}
                  />
                  <Bar dataKey="count" fill="#6200ee">
                    {insights.loadTimeDistribution.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={`#${Math.floor(index * 30 + 98).toString(16)}00ee`} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="site-chart-box">
              <h3>HTTP Status Codes</h3>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={Object.entries(metrics.statusCodes).map(([code, count]) => ({
                      name: `${code} (${count})`,
                      value: count
                    }))}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    label={({name, percent}) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  >
                    {Object.entries(metrics.statusCodes).map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={`hsl(${index * 45}, 70%, 50%)`} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Análise de Links */}
          <div className="site-charts-container">
            <div className="site-chart-box">
              <h3>Links Distribution</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={insights.linkDistribution}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="url" tick={false} />
                  <YAxis />
                  <Tooltip
                    content={({ payload, label }) => {
                      if (payload && payload.length) {
                        return (
                          <div className="custom-tooltip">
                            <p style={{ color: '#e0e0e0' }}>{`URL: ${label}`}</p>
                            <p style={{ color: '#8884d8' }}>{`Internal Links: ${payload[0].value}`}</p>
                            <p style={{ color: '#82ca9d' }}>{`External Links: ${payload[1].value}`}</p>
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <Legend />
                  <Bar dataKey="internal" name="Internal Links" fill="#8884d8" />
                  <Bar dataKey="external" name="External Links" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="site-chart-box">
              <h3>Top External Domains</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={insights.topExternalDomains} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="domain" type="category" width={150} />
                  <Tooltip />
                  <Bar dataKey="count" fill="#6200ee" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Análise SEO */}
          <div className="site-charts-container">
            <div className="site-chart-box">
              <h3>SEO Issues Distribution</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={insights.seoIssuesDistribution}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="issue" angle={-45} textAnchor="end" height={100} />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="count" fill="#6200ee">
                    {insights.seoIssuesDistribution.map((entry, index) => (
                      <Cell 
                        key={`cell-${index}`} 
                        fill={entry.severity === 'high' ? '#ff4444' : 
                              entry.severity === 'medium' ? '#ffbb33' : '#00C851'}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="site-chart-box">
              <h3>Meta Tags Presence</h3>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={[
                      { name: 'With Meta Description', value: data.length - metrics.seoMetrics.pagesWithMissingDescriptions },
                      { name: 'Missing Meta Description', value: metrics.seoMetrics.pagesWithMissingDescriptions },
                      { name: 'With Title', value: data.length - metrics.seoMetrics.pagesWithMissingTitles },
                      { name: 'Missing Title', value: metrics.seoMetrics.pagesWithMissingTitles }
                    ]}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={`hsl(${index * 90}, 70%, 50%)`} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Tabela de Dados */}
          <div className="site-table-container">
            <div className="site-table-actions">
              <div className="site-column-selector-container" ref={columnSelectorRef}>
                <button 
                  className="site-icon-button"
                  onClick={() => setShowColumnSelector(!showColumnSelector)}
                  title="Select Columns"
                >
                  <FaTable />
                </button>
                {showColumnSelector && (
                  <div className="site-column-selector-dropdown">
                    <div className="site-column-selector-header">
                      <h3>Visible Columns</h3>
                      <button 
                        className="site-select-all-button"
                        onClick={() => setSelectedColumns(columns.map(col => col.key))}
                      >
                        Select All
                      </button>
                    </div>
                    <div className="site-column-list">
                      {columns.map(col => (
                        <label key={col.key} className="site-column-option">
                          <input
                            type="checkbox"
                            checked={selectedColumns.includes(col.key)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedColumns([...selectedColumns, col.key]);
                              } else {
                                setSelectedColumns(selectedColumns.filter(c => c !== col.key));
                              }
                            }}
                          />
                          <span>{col.label}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <button 
                className="site-icon-button"
                onClick={exportToCSV}
                disabled={filteredData.length === 0}
                title="Download CSV"
              >
                <FaDownload />
              </button>
            </div>

            <div className="site-data-table">
              <table>
                <thead>
                  <tr>
                    {selectedColumns.map(colKey => {
                      const column = columns.find(col => col.key === colKey);
                      return (
                        <th key={colKey} onClick={() => handleSort(colKey)}>
                          <div className="site-header-content">
                            <span>{column.label}</span>
                            {renderSortIcon(colKey)}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {sortedAndPaginatedData.map((page, index) => (
                    <tr key={index}>
                      {selectedColumns.map(colKey => (
                        <td key={colKey}>
                          {colKey === 'seoScore' 
                            ? `${calculatePageSeoScore(page)}%`
                            : colKey === 'h1_tags'
                              ? (page[colKey]?.join(', ') || '-')
                              : (page[colKey] || '-')}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="site-pagination">
                <div className="site-rows-per-page">
                  <span>Rows per page:</span>
                  <select
                    value={rowsPerPage}
                    onChange={(e) => {
                      setRowsPerPage(Number(e.target.value));
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>

                <div className="site-page-navigation">
                  <span>{`${(currentPage - 1) * rowsPerPage + 1}-${Math.min(currentPage * rowsPerPage, filteredData.length)} of ${filteredData.length}`}</span>
                  <button
                    onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                    disabled={currentPage === 1}
                    className="site-page-button"
                  >
                    ←
                  </button>
                  <button
                    onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                    disabled={currentPage === totalPages}
                    className="site-page-button"
                  >
                    →
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SiteDashboard;

