import React, { useState, useEffect, useRef, useMemo } from "react";
//import UserHeader from "../components/UserHeader";
import { API_URL } from '../config/urlConfig';
import { useParams, useNavigate } from "react-router-dom";
import "../styles/YouTubeDashboard.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  ScatterChart,
  Scatter,
  ZAxis,
  Legend
} from 'recharts';
import { FaTable, FaDownload, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

const YouTubeDashboard = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [runs, setRuns] = useState([]);
  const [selectedRun, setSelectedRun] = useState("all");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const columnSelectorRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const [filters, setFilters] = useState({
    title: "",
    minViews: 0,
    maxViews: Number.MAX_SAFE_INTEGER,
    minLikes: 0,
    maxLikes: Number.MAX_SAFE_INTEGER,
    minComments: 0,
    maxComments: Number.MAX_SAFE_INTEGER,
    minDislikes: 0,
    maxDislikes: Number.MAX_SAFE_INTEGER
  });

  const [metrics, setMetrics] = useState({
    totalViews: 0,
    totalLikes: 0,
    averageComments: 0,
    mostUsedTags: [],
    viewsDistribution: [],
    engagementRate: 0
  });

  const [correlations, setCorrelations] = useState({
    tagsEngagement: [],
    viewsLikesData: [],
    topPerformingTags: [],
    publishTimeAnalysis: []
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('user');

    if (!token) {
      navigate('/login');
      return;
    }

    if (userData) {
      setUser(JSON.parse(userData));
    }

    const fetchData = async () => {
      try {
        const [dataResponse, runsResponse] = await Promise.all([
          fetch(`${API_URL}/youtube-data?projectId=${projectId}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }),
          fetch(`${API_URL}/runs?projectId=${projectId}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
        ]);

        const dataResult = await dataResponse.json();
        const runsResult = await runsResponse.json();

        if (dataResponse.ok && Array.isArray(dataResult.data)) {
          console.log('Data received:', dataResult.data);
          
          // Filtrar dados vazios
          const validData = dataResult.data.filter(item => 
            item && Object.keys(item).length > 0
          );
          
          console.log('Valid data count:', validData.length);
          setData(validData);

          if (validData.length > 0) {
            const availableColumns = Object.keys(validData[0]);
            setColumns(availableColumns);
            setSelectedColumns(["title", "views", "likes", "comments"]);
          }
        }

        if (runsResponse.ok && Array.isArray(runsResult.runs)) {
          console.log('Runs received:', runsResult.runs);
          setRuns(runsResult.runs);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectId, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (columnSelectorRef.current && !columnSelectorRef.current.contains(event.target)) {
        setShowColumnSelector(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      if (!item) return false;

      const views = parseInt(item.views) || 0;
      const likes = parseInt(item.likes) || 0;
      const comments = parseInt(item.comments) || 0;
      const dislikes = parseInt(item.dislikes) || 0;

      const isTitleMatch = !filters.title || 
        (item.title && item.title.toLowerCase().includes(filters.title.toLowerCase()));
      
      const isViewsMatch = views >= filters.minViews && 
        (filters.maxViews === Number.MAX_SAFE_INTEGER || views <= filters.maxViews);
      
      const isLikesMatch = likes >= filters.minLikes && 
        (filters.maxLikes === Number.MAX_SAFE_INTEGER || likes <= filters.maxLikes);
      
      const isCommentsMatch = comments >= filters.minComments && 
        (filters.maxComments === Number.MAX_SAFE_INTEGER || comments <= filters.maxComments);
      
      const isDislikesMatch = dislikes >= filters.minDislikes && 
        (filters.maxDislikes === Number.MAX_SAFE_INTEGER || dislikes <= filters.maxDislikes);
      
      const isRunMatch = selectedRun === "all" || (() => {
        const run = runs.find(r => r.id === item.run_id);
        const runName = run ? (run.name || `Run${run.id}`)
          : `Run${item.run_id}`;
        return runName === selectedRun;
      })();

      return isTitleMatch && isViewsMatch && isLikesMatch && 
             isCommentsMatch && isDislikesMatch && isRunMatch;
    });
  }, [data, selectedRun, filters, runs]);

  useEffect(() => {
    if (filteredData.length > 0) {
      const totalViews = filteredData.reduce((sum, item) => sum + (parseInt(item.views) || 0), 0);
      const totalLikes = filteredData.reduce((sum, item) => sum + (parseInt(item.likes) || 0), 0);
      const totalComments = filteredData.reduce((sum, item) => sum + (parseInt(item.comments) || 0), 0);

      const tagCount = {};
      filteredData.forEach(item => {
        if (item.tags && typeof item.tags === 'string') {
          const tags = item.tags.split(',').map(tag => tag.trim());
          tags.forEach(tag => {
            if (tag) {
              tagCount[tag] = (tagCount[tag] || 0) + 1;
            }
          });
        }
      });

      const mostUsedTags = Object.entries(tagCount)
        .sort(([,a], [,b]) => b - a)
        .slice(0, 10)
        .map(([tag, count]) => ({ tag, count }));

      const viewRanges = [
        { range: '0-1000', count: 0 },
        { range: '1k-10k', count: 0 },
        { range: '10k-100k', count: 0 },
        { range: '100k+', count: 0 }
      ];

      filteredData.forEach(item => {
        const views = parseInt(item.views) || 0;
        if (views < 1000) viewRanges[0].count++;
        else if (views < 10000) viewRanges[1].count++;
        else if (views < 100000) viewRanges[2].count++;
        else viewRanges[3].count++;
      });

      const engagementRate = totalViews > 0 
        ? ((totalLikes + totalComments) / totalViews * 100).toFixed(2)
        : 0;

      setMetrics({
        totalViews,
        totalLikes,
        averageComments: filteredData.length > 0 ? (totalComments / filteredData.length).toFixed(2) : 0,
        mostUsedTags,
        viewsDistribution: viewRanges,
        engagementRate
      });
    }
  }, [filteredData]);

  const calculateCorrelations = (filteredData) => {
    const tagsAnalysis = filteredData.map(item => {
      const tags = item.tags ? item.tags.split(',').length : 0;
      const engagement = ((item.likes + item.comments) / item.views) * 100;
      return {
        numberOfTags: tags,
        engagementRate: parseFloat(engagement.toFixed(2))
      };
    });

    const viewsLikesData = filteredData.map(item => ({
      views: parseInt(item.views),
      likes: parseInt(item.likes),
      title: item.title
    }));

    const tagPerformance = {};
    filteredData.forEach(item => {
      if (item.tags) {
        const tags = item.tags.split(',').map(tag => tag.trim());
        tags.forEach(tag => {
          if (!tagPerformance[tag]) {
            tagPerformance[tag] = {
              tag,
              totalViews: 0,
              totalLikes: 0,
              count: 0
            };
          }
          tagPerformance[tag].totalViews += parseInt(item.views);
          tagPerformance[tag].totalLikes += parseInt(item.likes);
          tagPerformance[tag].count += 1;
        });
      }
    });

    const topPerformingTags = Object.values(tagPerformance)
      .map(tag => ({
        tag: tag.tag,
        avgViews: Math.round(tag.totalViews / tag.count),
        avgLikes: Math.round(tag.totalLikes / tag.count),
        count: tag.count
      }))
      .sort((a, b) => b.avgViews - a.avgViews)
      .slice(0, 10);

    setCorrelations({
      tagsEngagement: tagsAnalysis,
      viewsLikesData,
      topPerformingTags
    });
  };

  useEffect(() => {
    if (filteredData.length > 0) {
      calculateCorrelations(filteredData);
    }
  }, [filteredData]);

  const handleSort = (key) => {
    setSortConfig(prevSort => ({
      key,
      direction: prevSort.key === key && prevSort.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const toggleColumn = (column) => {
    setSelectedColumns((prevColumns) =>
      prevColumns.includes(column)
        ? prevColumns.filter((col) => col !== column)
        : [...prevColumns, column]
    );
  };

  const exportToCSV = () => {
    if (filteredData.length === 0) return;

    const csvContent = [
      selectedColumns.join(','),
      ...filteredData.map(item => 
        selectedColumns.map(col => {
          const value = item[col]?.toString() || '';
          return value.includes(',') ? `"${value.replace(/"/g, '""')}"` : value;
        }).join(',')
      )
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `youtube_data_${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Função para ordenar dados
  const sortData = (data, sortKey, direction) => {
    if (!sortKey) return data;

    return [...data].sort((a, b) => {
      let aValue = a[sortKey];
      let bValue = b[sortKey];

      // Converter para número se possível
      if (!isNaN(aValue) && !isNaN(bValue)) {
        aValue = Number(aValue);
        bValue = Number(bValue);
      }

      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  // Dados ordenados e paginados
  const sortedAndPaginatedData = useMemo(() => {
    const sortedData = sortData(filteredData, sortConfig.key, sortConfig.direction);
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredData, sortConfig, currentPage, rowsPerPage]);

  // Total de páginas
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  // Função para renderizar o ícone de ordenação
  const renderSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <FaSort className="youtube-sort-icon" />;
    }
    return sortConfig.direction === 'asc' ? 
      <FaSortUp className="youtube-sort-icon active" /> : 
      <FaSortDown className="youtube-sort-icon active" />;
  };

  return (
    <div>
      {/*<UserHeader user={user} />*/}
      <div className="youtube-dashboard">
        <div className="youtube-dashboard-header">
          <h1>YouTube Dashboard</h1>
        </div>

        {loading ? (
          <div className="loading">Loading...</div>
        ) : (
          <>
            <div className="youtube-filters">
              <label>
                Run:
                <select 
                  value={selectedRun} 
                  onChange={(e) => {
                    console.log('Selected Run:', e.target.value);
                    console.log('Data before filter:', data.length);
                    setSelectedRun(e.target.value);
                  }}
                >
                  <option value="all">All Runs</option>
                  {runs.map((run) => (
                    <option 
                      key={run.id} 
                      value={run.name || `Run${run.id}`}
                    >
                      {run.name || `Run ${run.id}`} - {new Date(run.created_at).toLocaleDateString()}
                    </option>
                  ))}
                </select>
              </label>

              <label>
                Search by Title:
                <input 
                  type="text" 
                  value={filters.title} 
                  onChange={(e) => setFilters({ ...filters, title: e.target.value })} 
                  placeholder="Enter title..."
                />
              </label>

              <label>
                Likes Range:
                <div className="youtube-range-inputs">
                  <input 
                    type="number" 
                    value={filters.minLikes} 
                    onChange={(e) => setFilters({ ...filters, minLikes: Number(e.target.value) })}
                    placeholder="Min"
                  />
                  <input 
                    type="number" 
                    value={filters.maxLikes} 
                    onChange={(e) => setFilters({ ...filters, maxLikes: Number(e.target.value) || Number.MAX_SAFE_INTEGER })}
                    placeholder="Max"
                  />
                </div>
              </label>

              <label>
                Comments Range:
                <div className="youtube-range-inputs">
                  <input 
                    type="number" 
                    value={filters.minComments} 
                    onChange={(e) => setFilters({ ...filters, minComments: Number(e.target.value) })}
                    placeholder="Min"
                  />
                  <input 
                    type="number" 
                    value={filters.maxComments} 
                    onChange={(e) => setFilters({ ...filters, maxComments: Number(e.target.value) || Number.MAX_SAFE_INTEGER })}
                    placeholder="Max"
                  />
                </div>
              </label>
            </div>

            <div className="youtube-metrics-cards">
              <div className="youtube-metric-card">
                <h3>Total Views</h3>
                <p>{metrics.totalViews.toLocaleString()}</p>
              </div>
              <div className="youtube-metric-card">
                <h3>Total Likes</h3>
                <p>{metrics.totalLikes.toLocaleString()}</p>
              </div>
              <div className="youtube-metric-card">
                <h3>Avg. Comments</h3>
                <p>{metrics.averageComments}</p>
              </div>
              <div className="youtube-metric-card">
                <h3>Engagement Rate</h3>
                <p>{metrics.engagementRate}%</p>
              </div>
            </div>

            <div className="youtube-charts-container">
              <div className="youtube-chart-box">
                <h3>Views Distribution</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={metrics.viewsDistribution}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="range" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#6200ee" />
                  </BarChart>
                </ResponsiveContainer>
              </div>

              <div className="youtube-chart-box">
                <h3>Most Used Tags</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={metrics.mostUsedTags}
                      dataKey="count"
                      nameKey="tag"
                      cx="50%"
                      cy="50%"
                      outerRadius={100}
                      fill="#6200ee"
                      label
                    >
                      {metrics.mostUsedTags.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={`hsl(${index * 36}, 70%, 50%)`} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="youtube-correlations-container">
              <div className="youtube-correlation-box">
                <h3>Tags vs Engagement Rate</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                    <CartesianGrid />
                    <XAxis 
                      dataKey="numberOfTags" 
                      name="Number of Tags" 
                      type="number"
                      label={{ value: "Number of Tags", position: "bottom" }}
                    />
                    <YAxis 
                      dataKey="engagementRate" 
                      name="Engagement Rate (%)"
                      label={{ value: "Engagement Rate (%)", angle: -90, position: "insideLeft" }}
                    />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    <Scatter name="Tags-Engagement" data={correlations.tagsEngagement} fill="#8884d8" />
                  </ScatterChart>
                </ResponsiveContainer>
              </div>

              <div className="youtube-correlation-box">
                <h3>Views vs Likes Correlation</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                    <CartesianGrid />
                    <XAxis 
                      dataKey="views" 
                      name="Views" 
                      type="number"
                      label={{ value: "Views", position: "bottom" }}
                    />
                    <YAxis 
                      dataKey="likes" 
                      name="Likes"
                      label={{ value: "Likes", angle: -90, position: "insideLeft" }}
                    />
                    <Tooltip 
                      cursor={{ strokeDasharray: '3 3' }}
                      content={({ payload }) => {
                        if (payload && payload.length) {
                          const data = payload[0].payload;
                          return (
                            <div className="youtube-custom-tooltip">
                              <p>{`Title: ${data.title}`}</p>
                              <p>{`Views: ${data.views.toLocaleString()}`}</p>
                              <p>{`Likes: ${data.likes.toLocaleString()}`}</p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Scatter name="Views-Likes" data={correlations.viewsLikesData} fill="#82ca9d" />
                  </ScatterChart>
                </ResponsiveContainer>
              </div>

              <div className="youtube-correlation-box">
                <h3>Top Performing Tags</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={correlations.topPerformingTags}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="tag" 
                      angle={-45}
                      textAnchor="end"
                      interval={0}
                      height={100}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="avgViews" name="Avg. Views" fill="#8884d8" />
                    <Bar dataKey="avgLikes" name="Avg. Likes" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="youtube-table-container">
              <div className="youtube-table-actions">
                <div className="youtube-column-selector-container" ref={columnSelectorRef}>
                  <button 
                    className="youtube-icon-button"
                    onClick={() => setShowColumnSelector(!showColumnSelector)}
                    title="Selecionar Colunas"
                  >
                    <FaTable />
                  </button>
                  {showColumnSelector && (
                    <div className="youtube-column-selector-dropdown">
                      <div className="youtube-column-selector-header">
                        <h3>Colunas Visíveis</h3>
                        <button 
                          className="youtube-select-all-button"
                          onClick={() => setSelectedColumns(columns)}
                        >
                          Selecionar Todas
                        </button>
                      </div>
                      <div className="youtube-column-list">
                        {columns.map(col => (
                          <label key={col} className="youtube-column-option">
                            <input
                              type="checkbox"
                              checked={selectedColumns.includes(col)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedColumns([...selectedColumns, col]);
                                } else {
                                  setSelectedColumns(selectedColumns.filter(c => c !== col));
                                }
                              }}
                            />
                            <span>{col}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <button 
                  className="youtube-icon-button"
                  onClick={exportToCSV}
                  disabled={filteredData.length === 0}
                  title="Download CSV"
                >
                  <FaDownload />
                </button>
              </div>

              <div className="youtube-data-table">
                <table>
                  <thead>
                    <tr>
                      {selectedColumns.map(col => (
                        <th 
                          key={col}
                          onClick={() => handleSort(col)}
                          className="youtube-sortable-header"
                        >
                          <div className="youtube-header-content">
                            <span>{col.toUpperCase()}</span>
                            {renderSortIcon(col)}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedAndPaginatedData.map((item, index) => (
                      <tr key={index}>
                        {selectedColumns.map((col) => (
                          <td key={col}>{item[col]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Paginação */}
                <div className="youtube-pagination">
                  <div className="youtube-rows-per-page">
                    <span>Linhas por página:</span>
                    <select
                      value={rowsPerPage}
                      onChange={(e) => {
                        setRowsPerPage(Number(e.target.value));
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>

                  <div className="youtube-page-navigation">
                    <span>{`${(currentPage - 1) * rowsPerPage + 1}-${Math.min(currentPage * rowsPerPage, filteredData.length)} de ${filteredData.length}`}</span>
                    <button
                      onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                      disabled={currentPage === 1}
                      className="youtube-page-button"
                    >
                      ←
                    </button>
                    <button
                      onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                      disabled={currentPage === totalPages}
                      className="youtube-page-button"
                    >
                      →
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default YouTubeDashboard;
